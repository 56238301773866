.main_title{
    @apply  text-black text-2xl font-medium
}


.css-13cymwt-control{
    border-width: 0px !important;
    background-color: transparent !important;
    min-height:46px !important
}


#react-select-5-input::placeholder{
    color: black !important;
}


#mainbg{
    background: linear-gradient(23deg, rgba(103,210,220,1) 0%, rgba(167,130,235,1) 49%, rgba(221,60,237,1) 77%, rgba(255,0,224,1) 100%);
    color: white;
}

#mainbglog{
    background: linear-gradient(170deg, purple, blue, purple);}

#mainbglogs{
    background: rgb(196, 196, 196);}

#mainbghover:hover{
    background: linear-gradient(23deg, rgba(103,210,220,1) 0%, rgba(167,130,235,1) 49%, rgba(221,60,237,1) 77%, rgba(255,0,224,1) 100%);
}

.param{

    @apply text-white text-sm  font-medium
}


/* Scrollbar Styling */
.overflow-x-auto::-webkit-scrollbar {
    width: 5px;
}
 
.overflow-x-auto::-webkit-scrollbar-track {
    background-color: #ebebeb;
    -webkit-border-radius: 10px;
    border-radius: 10px;
}

.overflow-x-auto::-webkit-scrollbar-thumb {
    -webkit-border-radius: 10px;
    border-radius: 10px;
    background: #FFC90D; 
}



.fancy-text {
    font-family: 'Poppins', sans-serif; /* You can change this to any desired font */
    background: linear-gradient(90deg, purple, blue, purple);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 2rem; /* Adjust size as needed */
    font-weight: bold;
    /* text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3); */
  }